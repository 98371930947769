import ReactPixel from "react-facebook-pixel";
import { LineContactImage } from "src/assets";
import { LineSupportLink } from "src/main/constants";
import { isMobile } from "src/main/utils";

export default function LineSupportButton() {
  const handleClick = () => {
    ReactPixel.track("AddToCart");
    window.open(LineSupportLink, "_blank", isMobile() ? "width=full,height=full" : "");
  };

  return (
    <img
      src={LineContactImage}
      alt="line contact"
      style={{
        maxWidth: 120,
        position: "fixed",
        bottom: 0,
        right: 0,
        cursor: "pointer",
        animation: "action 1.8s infinite alternate",
        zIndex: 10,
      }}
      onClick={handleClick}
    />
  );
}
