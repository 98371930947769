import BigNumber from "bignumber.js";

export const BN_ZERO = new BigNumber(0);
export const BN_ONE = new BigNumber(1);

export const Paths = {
  Landing: {
    Index: "/",
  },
};

export const DEFAULT_URL = "https://m.chaiyoth268.com";

export const PUSH_NOTIFICATION_STORAGE_KEY = "push-notification-register-status";
export const REGISTERED = "REGISTERED";

export const DEFAULT_ENTER_SITE_URL = "https://m.chaiyoth268.com/?action=register";

export const ENTER_SITE_URL_MAP = {
  "candywin99.com": "https://a.vtrk1.com/2751fa3b-7b99-4372-9c86-3bda7bd6c41f",
  "cy26888.net": "https://m.cycy288.com",
  "cycy26888.com": "https://m.cycy288.com",
  "cy2688cy.com": "https://m.cycy288.com",
  "c288y.com": "https://m.cycy288.com",

  "c268y.com": "https://m.cycy2688.com",
  "cyy268.com": "https://m.cycy2688.com",
};

export const LineSupportLink = "https://cy268thai.com/contact";

export * from "./codes";
export * from "./context";
