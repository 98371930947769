import { VisuallyHidden } from "@99tech/ui";
import { Box } from "@mui/material";
import React from "react";
import { BubbleGreen, BubbleRed, CharBack, CharFront, EnterSite, Logo } from "src/assets";
import { LineSupportButton } from "src/main/components";
import { createStyles } from "src/main/utils";

interface LandingPageProps extends React.PropsWithChildren {}

const LandingPage: React.FC<LandingPageProps> = (props) => {
  return (
    <Box sx={styles.root}>
      <VisuallyHidden as="h1">ไชโย 268</VisuallyHidden>
      <Box sx={{ position: "relative", width: 360, textAlign: "center" }}>
        <img
          alt="logo"
          src={Logo}
        />
      </Box>

      <Box sx={styles.images}>
        <img
          alt="bubble green"
          src={BubbleGreen}
          style={{ left: 20, top: -20 }}
        />

        <img
          alt="bubble red"
          src={BubbleRed}
          style={{ right: 10, top: -40 }}
        />

        <img
          alt="char back"
          src={CharBack}
          style={{ left: 0, right: 0, margin: "0 auto", top: 80 }}
        />

        <img
          alt="char front"
          src={CharFront}
          style={{ left: 0, right: 0, margin: "0 auto", top: 250 }}
        />

        <img
          alt="enter site"
          src={EnterSite}
          style={{ width: 243, left: 0, right: 0, margin: "0 auto", top: 0 }}
        />
      </Box>

      <LineSupportButton />
    </Box>
  );
};

const styles = createStyles({
  root: {
    pt: "26px",
    pb: "20px",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    gap: "0px",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    overflowX: "hidden",
  },
  images: {
    zIndex: 10,
    position: "relative",
    width: "360px",
    height: "445px",
    img: {
      position: "absolute",
    },
  },
  footer: {
    zIndex: 0,
    position: "fixed",
    bottom: "0",
    width: "100vw",
    height: "200px",
    flexShrink: 0,
  },
});

export default LandingPage;
